//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import Block from '@/components/Together/Block.vue';
import DateComponent from '@/components/Together/DateComponent.vue';
import Reaction from '@/components/Together/Reaction.vue';
import { Posts } from '@/services/api.service';

export default {
  name: 'Post',
  data() {
    return {
      hoverMy: false,
      hoverOther: false,
      menuVisible: false,
      hoverTimeout: null,
    };
  },
  props: ['post', 'topic', 'event', 'venue', 'isPage', 'color', 'bgColor'],
  components: {
    Block,
    DateComponent,
    SanghaAvatar,
    Reaction,
  },
  methods: {
    mouseOver() {
      this.hoverTimeout = setTimeout(() => {
        this.hoverMy = this.isMe ? true : false;
        this.hoverOther = this.isMe ? false : true;
      }, 300);
    },
    mouseOut() {
      this.clearHover();
    },
    showReplies(post) {
      post.showReplies = !post.showReplies;
    },
    clearHover() {
      if (this.menuVisible) return;
      setTimeout(() => {
        this.hoverMy = false;
        this.hoverOther = false;
      }, 300);
    },
    deleteMessage() {
      Posts.deleteMessage(this.post.id).then(
        () => {
          this.post.deletedAt = new Date();
        },
        () => {}
      );
    },
    editMessage() {
      this.$emit('onEditMessage', { post: this.post });
      return true;
    },
    gotoDetail(post) {
      if (this.topic && this.topic.topic) {
        let topic = this.topic.topic.id;
        this.$router.push({
          name: 'thread',
          params: { type: 'topic', id: topic, postId: post.id },
        });
      }
      if (this.event) {
        let event = this.event.id;
        this.$router.push({
          name: 'thread',
          params: { type: 'event', id: event, postId: post.id },
        });
      }
      if (this.venue) {
        let venue = this.venue.id;
        this.$router.push({
          name: 'thread',
          params: { type: 'venue', id: venue, postId: post.id },
        });
      }
    },
    gotoDetailIfNotPage(post) {
      if (this.isPage) return;
      this.gotoDetail(post);
    },
  },
  watch: {},
  computed: {
    isMe() {
      if (this.$store.state.profile.id) {
        return this.post.author.id === this.$store.state.profile.id;
      }
      return false;
    },
    isMessageEditable() {
      return this.post._postType && this.post._postType === 'PARAGRAPH';
    },
  },
  mounted() {},
};
