//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Posts } from '@/services/api.service';
import UserReactionListModal from '@/components/Together/UserReactionListModal';

export default {
  name: 'Reaction',
  data() {
    return {
      availableReactions: [
        { name: 'Like', id: 'REACTION_1', icon: '❤️' },
        { name: 'In wish', id: 'REACTION_2', icon: '🙏' },
        { name: 'Thumbs up', id: 'REACTION_3', icon: '👍' },
        { name: `Rainbow 'A'`, id: 'REACTION_4', icon: '🌈' },
        { name: 'Smile', id: 'REACTION_5', icon: '😊' },
      ],
      showReactionsMenu: false,
      showReactionModal: false,
      initialReaction: null,
      initialPostId: null,
      myReaction: null,
    };
  },
  props: ['isMe', 'post', 'hoverMy', 'hoverOther'],
  components: {
    UserReactionListModal
  },
  methods: {
    reactToMessage(postId, reactionId, $event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      Posts.react({ messageId: postId, reaction: reactionId });
    },
    showMessageReactions(postId, reaction) {
      this.initialReaction = reaction;
      this.initialPostId = postId;
      this.showReactionModal = true;
      this.showReactionsMenu = false; // hide reaction list/menu
    },
    getIcon(reactionId) {
      return this.availableReactions.filter((i) => i.id === reactionId)[0].icon;
    },
    getMyReactions() {
      let myReaction = null;
      if (!this.post || !this.post.reactions) return;
      this.post.reactions.forEach(i => {
        if (i && i.reacted) myReaction = i.reaction
      });
      this.myReaction = myReaction;
    }
  },
  watch: {
    'post.reactions'() {
      this.getMyReactions();
    }
  },
  computed: {},
  mounted() {
    this.getMyReactions()
  },
};
