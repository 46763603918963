import { render, staticRenderFns } from "./Block.vue?vue&type=template&id=eb2e783e&"
import script from "./Block.vue?vue&type=script&lang=js&"
export * from "./Block.vue?vue&type=script&lang=js&"
import style0 from "./Block.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QIcon,QDialog,QBtn,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon,QDialog,QBtn,QImg})
