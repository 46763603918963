//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaImage from '@/components/SanghaImage.vue';
import VueHighlights, { autoLink } from 'vue-highlights';

export default {
  name: 'Block',
  data() {
    return {
      showImageModal: false,
    };
  },
  props: ['content', 'color'],
  components: {
    SanghaImage,
    VueHighlights,
  },
  methods: {
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    download(url) {
      window.open(url);
    },
  },
  watch: {},
  computed: {
    parsedContent() {
      let ret = this.content;
      const urlReg =
         /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=!]*)/;

      this.content.forEach((single, key) => {
        if (single.type == 'PARAGRAPH') {
          single.data.forEach((block, blockKey) => {
            const linkMatch = block.match(urlReg);
            if (linkMatch) {
              const youtubeId = this.getYoutubeId(linkMatch[0]);
              if (youtubeId) {
                ret.push({ type: 'YOUTUBE', data: { videoId: youtubeId } }); // create new element with type YT
                ret[key].data[blockKey] = block.replace(linkMatch[0], ''); // replace original link in text with emptiness
              } else {
                ret[key].data[blockKey] = autoLink(
                  ret[key].original[blockKey],
                  {
                    extractUrlsWithoutProtocol: true, // Defaults to true
                    targetBlank: true, // Defauls to true, applies only in URLs
                    urlClass: 'url-class',
                  }
                );
              }
            }
          });
        }
        if (single.type == 'GALLERY') {
          if (!single.original)
            single.original = JSON.parse(JSON.stringify(single.data));
        }
      });
      return ret;
    },
  },
  mounted() {},
};
